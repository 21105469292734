@import '../globals';

.hp-container__contact-me {
  @include wh(100%, 100%);
  display: flex;

  grid-column-start: 2;
  grid-column-end: 3;  
  grid-row-start: 1;
  grid-row-end: 2;
  
  @media (max-width: 1250px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 4;
    grid-row-end: 5;
    height: 250px;
  }

  &:link {
    text-decoration: none;
  }

  &:hover {
    gap: 30px
  }

  &__text-box {
    font-size: clamp(16px, 5vw, 96px);
    border-radius: 20px 20px;
    font-weight: bold;
    color: white;
    padding: 20px 35px 0;
    background: $soft-dark;
    @include wh(100%, 100%);
    display: flex;
    justify-content: space-between;
    transition: 0.3s ease;
    position: relative;

    @media (max-width: 1250px) {
      font-size: clamp(16px, 7.2vw, 90px);  
    }
    
    @media (max-width: 750px) {
      font-size: clamp(16px, 10vw, 75px);   
    }

    &__inf {
      // border: 1px solid red;
      @include wh(62.5%, 23%);
      margin-right: 35px;
      top: 60%;
      right: 0;
      background-size: auto 100%;
      background-repeat: no-repeat;
      position: absolute;
    }
    
    @media (max-width: 1250px) {
      &__inf{
        @include wh(62.5%, 23%);
        animation-name: contact-inf;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-duration: 4s;
        
        @media (max-width: 750px) {
          @include wh(85%, 17%);
          top: 77.5%;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    @media (min-width: 1251px) {
      &:hover > &__inf {
        animation-name: contact-inf;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-duration: 4s;
      }
    } 
  }

  // &:hover > &__text-box > &__text-box__inf {
  //   animation-name: contact-inf;
  //   animation-iteration-count: infinite;
  //   animation-timing-function: linear;
  //   animation-duration: 3.5s;
  //   @media (max-width: 1250px) {
  //     animation-duration: 2.5s;
  //   }  
  // }
  
  @media (max-width: 750px) {
    &__text-box {
      @include wh(85%, 100%);
      border-radius: 20px 0 0 20px;
    }

    gap: 30px    
  }
  
  &__arrow-section {
    transition: 0.3s ease;
    border-radius: 0 20px 20px 0;
    width: 0%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    &__arrow {
      transition: 0.3s ease;
      margin-top: 45px;
      width: 35%;
      
      @media (max-width: 750px) {
        margin-top: 30px;
        width: 40%;
      }
    }

    &:hover > &__arrow {
      margin-top: 30px;
      margin-left: 15px;
      
      @media (max-width: 750px) {
        margin-top: 20px;
        margin-left: 5px;
      }
    }
  }
  
  @media (min-width: 751px) {
    &:hover > &__arrow-section {
      @include wh(15%, 100%);
      border: clamp(1px, 0.235vw, 4.5px) solid $orange;
    }
  }

  @media (max-width: 750px) {
    &__arrow-section {
      @include wh(15%, 100%);
      border: clamp(1px, 1vw, 3.5px) solid $orange;
    }
  }
}

@keyframes contact-inf {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}