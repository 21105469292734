@mixin wh($width, $heigth) {
  width: $width;
  height: $heigth;
}

@mixin flex-jawd($justify-content, $align-items, $flex-wrap, $flex-direction) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
  flex-wrap: $flex-wrap;
  flex-direction: $flex-direction;
}
