@import '../globals/index';

.am-container__skills {
  // border: 0.5px solid yellow;
  padding: 0 clamp(16px, 24vw, 1196px) 200px;

  @media (max-width: 1250px) {
    padding: 0 clamp(16px, 20vw, 1000px) 175px;
  }

  @media (max-width: 750px) {
    padding: 0 clamp(16px, 16vw, 1000px) 125px;
  }

  &__titles {
    // border: 1px dotted blue;
    margin-bottom: 65px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 750px) {
      margin-bottom: 40px;
    }
    
    &__main-title {
      // border: 1px solid red;
      font-size: clamp(40px, 5vw, 96px);
      font-family: 'AdineueProBold';
      
      & span {
        color: $orange;
      }
    }
    
    &__xp-title {
      // border: 1px solid red;
      font-size: clamp(16px, 1.67vw, 32px);
      font-family: 'CascadiaRegular';
      font-weight: lighter;
      text-align: right;
      align-self: flex-end;
      padding-bottom: 25px;
    }
  }

  &__content {
    // border: 1px dashed white;
    font-size: clamp(16px, 1.67vw, 32px);
    font-family: 'CascadiaLight';
    font-weight: lighter;
    display: flex;
    justify-content: space-between;
    
    &__items {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      
      @media (max-width: 750px) {
        gap: 2.5px;
      }

      &__container{
        // border: 1px solid red;
        white-space: nowrap;
        width: 100%;
        display: flex;
        justify-content: space-around;
        cursor: default;
        
        &:hover > &__xp, &:hover > &__line {
          color: $orange;
          border-color: $orange;
        }
        
        &__text {
          padding-right: 15px;

          & span {
            color: $orange;
            font-style: italic;

            @media (max-width: 600px) {
              display: none;              
            }          
          }
        }
        
        &__line {
          border-top: 1px solid $soft-dark;
          width: stretch;
          margin: auto 0;
        }

        &__xp {
          padding-left: 15px;
        }
      }
    }
  }
}