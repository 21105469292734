@import '../globals';

.hp-container__profile {
  // border: 1px solid blue;
  @include wh(100%, 100%);
  grid-row-start: 1;
  grid-row-end: 3;
  font-family: 'AdineueProBold', adineue PRO;
  display: flex;
  // flex-wrap: wrap;
  justify-content: space-between;
  gap: 5%;
  
  @media (max-width: 1250px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    height: 500px; 
  }

  @media (max-width: 750px) {
    height: 800px;
    flex-direction: column-reverse;
  }

  &__img-container{
    // border: 3px dashed blue;
    @include wh(45%, 95%);
    background-image: url('../../img/profile-photo.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 15px;
    align-self: flex-end;
    position: relative;
    
    @media (max-width: 1250px) {
      background-position: center 0;
    }

    @media (max-width: 750px) {
      @include wh(97.5%, 60%);
      align-self: flex-start;

    }
    
    &::after {
      content: '';
      border: clamp(1px, 0.235vw, 4.5px) solid $white;
      border-radius: inherit;
      @include wh(100%, 100%);
      position: absolute;
      top: -23px;
      right: -35px;
      z-index: -10;
      
      @media (max-width: 1250px) {
        border: clamp(1px, 1vw, 4.5px) solid $white;
      }
      
      @media (max-width: 750px) {
        border: clamp(1px, 1vw, 3.5px) solid $white;
        top: 15px;
        right: -18px;  
      }
    }
  }
  
  &__text-container {
    // border: 3px dashed yellow;
    @include wh(40%, 100%);
    margin-right: 39.5px;
    display: flex;
    letter-spacing: 5px;
    user-select: none;
    
    @media (max-width: 750px) {
      @include wh(100%, 40%);
      flex-wrap: wrap;
    }  

    &__hi {
      // border: 1px solid blue;
      font-size: clamp(16px, 5vw, 96px);
      width: 33.33333333333333%;
      transform: rotate(-180deg);
      writing-mode: tb-rl;
      color: $white;
      display: flex;
      align-items: center;
      cursor: default;
      
      @media (max-width: 1250px) {
        font-size: clamp(16px, 7.2vw, 90px);  
      }
      
      @media (max-width: 750px) {
        font-size: clamp(16px, 10vw, 75px);  
        transform: rotate(0deg);
        writing-mode: horizontal-tb;
        width: 100%;
        height: 33.33333333333333%;
      }  
      
      &__text {
        height: 25%;
        
        @media (max-width: 750px) {
          height: auto;
          width: 18%;
        } 
      }

      &__line {
        border-right: clamp(1px, 0.235vw, 4.5px) solid $white;
        height: 75%;
        transition: 0.3s ease;
        
        @media (max-width: 1250px) {
          border-right: clamp(1px, 1vw, 4.5px) solid $white;
        }
        
        @media (max-width: 750px) {
          border-right: none;
          height: 0;
          border-top: clamp(1px, 1vw, 3.5px) solid $white;
          width: 82%;
        }    
      }

      &:hover > &__line{
        height: 70%;

        @media (max-width: 750px) {
          height: 0%;
          width: 77%;
        }
      }
    }

    &__i-am {
      // border: 1px solid red;
      font-size: clamp(16px, 5vw, 96px);
      transform: rotate(-180deg);
      writing-mode: tb-rl;
      width: 33.33333333333333%;
      color: $white;
      display: flex;
      align-items: center; 
      cursor: default;

      @media (max-width: 1250px) {
        font-size: clamp(16px, 7.2vw, 90px);
      }

      @media (max-width: 750px) {
        font-size: clamp(16px, 10vw, 75px);  
        width: 100%;
        height: 33.33333333333333%;
        transform: rotate(0deg);
        writing-mode: horizontal-tb;
      }

      &__text {
        height: 45%;
        @media (max-width: 750px) {
          height: auto;
          width: 30%;
        }
      }

      &__line {
        border-right: clamp(1px, 0.235vw, 4.5px) solid $white;
        height: 55%;
        transition: 0.3s ease;

        @media (max-width: 1250px) {
          border-right: clamp(1px, 1vw, 4.5px) solid $white;
        }

        @media (max-width: 750px) {
          border-right: none;
          height: 0;
          border-top: clamp(1px, 1vw, 3.5px) solid $white;
          width: 70%;
        }
      }

      &:hover > &__line{
        height: 50%;
        @media (max-width: 750px) {
          height: 0;
          width: 65%;
        }
      }
    }

    &__abimael-wrapper {
      width: 33.33333333333333%;
      transform: rotate(-180deg);
      
      @media (max-width: 750px) {
        width: 100%;
        height: 33.33333333333333%;
        transform: rotate(0deg);
      }

      &__abimael {
        border: clamp(1px, 0.235vw, 4.5px) solid $white;
        writing-mode: tb-rl;
        border-radius: 15px;
        color: $white;
        @include wh(100%, 95%);
        padding-top: 5px;
        font-size: clamp(16px, 5vw, 96px);
        cursor: pointer;
        transition: 0.3s ease;  
        position: relative;
        background-color: $dark;
        display: flex;
        align-items: center;
        
        @media (max-width: 1250px) {
          border: clamp(1px, 1vw, 4.5px) solid $white;
          font-size: clamp(16px, 7.2vw, 90px);  
        }

        @media (max-width: 750px) {
          font-size: clamp(16px, 10vw, 75px);
          border: clamp(1px, 1vw, 3.5px) solid $white;
          padding-left: 5px;
          padding-top: 15px;
          @include wh(97.5%, 100%);
          writing-mode: horizontal-tb;
        }

        &::after{
          border: clamp(1px, 0.235vw, 4.5px) solid $white;
          border-radius: 15px;
          content: '';
          display: block;
          @include wh(100%, 100%);
          position: absolute;
          z-index: -1;
          top: 20px;
          right: 35px;

          @media (max-width: 1250px) {
            border: clamp(1px, 1vw, 4.5px) solid $white;
          }
          
          @media (max-width: 750px) {
            border: clamp(1px, 1vw, 3.5px) solid $white;
            writing-mode: horizontal-tb;
            right: -22.5px;  
          }  
        }

        &:hover {
          color: $orange;
        }

        &__text {
          // letter-spacing: 0px;
          padding-right: 10px;
        }

        &__line {
          display: none;
        }
      }
    }
  }
}