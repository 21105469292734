@import '../globals/';

.hp-container__about-me {
  @include wh(100%, 100%);
  grid-column-start: 1;
  grid-column-end: 2;  
  grid-row-start: 3;
  grid-row-end: 4;

  font-size: clamp(16px, 5vw, 96px);
  font-weight: bold;
  padding: 20px 0 0 35px;
  background-color: $soft-dark;  
  border-radius: 20px;
  color: white;
  position: relative;

  @media (max-width: 1250px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    height: 250px;
    font-size: clamp(16px, 7.2vw, 90px);  
  }

  @media (max-width: 750px) {
    font-size: clamp(16px, 10vw, 75px);   
  }

  &:link {
    text-decoration: none;
  }

  &__inf {
    // border: 1px solid red;
    @include wh(62.5%, 24%);
    background-size: auto 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: 60%;
    right: 35px; 
  }

  @media (max-width: 1250px) {
    &__inf {
      @include wh(62.5%, 21.5%);
      animation-name: about-me-inf;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-duration: 4s;
      
      @media (max-width: 750px) {
        @include wh(85%, 15%);
        top: 77.5%;
        left: 50%;
        transform: translateX(-50%); 
      }
    }
  }

  @media (min-width: 1251px) {
    &:hover > &__inf {
      animation-name: about-me-inf;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-duration: 4s; 
    }
  }

  &:after {
    position: absolute;
    content: '';
    border-radius: 20px;
    @include wh(99%, 98%);
    top: 0px;
    left: 0px;
    border: clamp(1px, 0.235vw, 4.5px) solid transparent;
    transition: 0.2s ease;

    @media (max-width: 750px) {
      & {
        border: clamp(1px, 1vw, 3.5px) solid $orange;
        top: 12px;
        left: 12px;  
      }
    }
  }
  
  @media (min-width: 751px) {
    &:hover:after {
      border: clamp(1px, 0.235vw, 4.5px) solid $orange;
      top: -25px;
      left: 15px;
    }
  }
}

@keyframes about-me-inf {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}