@import '../globals';

.hp-container__my-projects {
  @include wh(100%, 100%);

  grid-row-start: 2;
  grid-row-end: 4;

  font-size: clamp(16px, 5vw, 96px);
  font-weight: bold;
  padding: 20px 0 0 35px;
  background-color: $soft-dark;  
  border-radius: 20px;
  color: white;
  position: relative;
  
  @media (max-width: 1250px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
    height: 500px;
    font-size: clamp(16px, 7.2vw, 90px);  
  }
  
  @media (max-width: 750px) {
    height: 400px;
    font-size: clamp(16px, 10vw, 75px);   
  }

  &:link {
    text-decoration: none;
  }
  
  & span {
    font-family: 'AdineuePro';
    font-weight: lighter;
  }

  &__brackets {
    transition: 0.2s ease;
    
    @media (max-width: 750px) {
      color: $orange;
    }
  }

  &:hover > &__brackets{
    color: $orange;
  }


  &__inf {
    // border: 1px solid red;
    @include wh(62.5%, 11.4%);
    background-size: auto 100%;
    background-repeat: no-repeat;
    position: absolute;
    right: 35px; 
    bottom: 7%;
  }

  @media (max-width: 1250px) {
    &__inf {
      animation-name: my-projects-inf;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-duration: 4s;
      
      @media (max-width: 750px) {
        @include wh(85%, 10.5%);
        bottom: 2.5%;
        left: 50%;
        transform: translateX(-50%); 
      }
    }
  }
  
  @media (min-width: 1251px) {
    &:hover > &__inf {
      animation-name: my-projects-inf;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-duration: 4s; 
    }
  }
}

@keyframes my-projects-inf {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}