@font-face {
  font-family: 'AdineuePro';
  src: url(../../fonts/adineue-PRO.ttf) format('truetype');
}

@font-face {
  font-family: 'AdineueProBold';
  src: url(../../fonts/adineue-PRO-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'CascadiaRegular';
  src: url(../../fonts/CascadiaCode-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'CascadiaItalic';
  src: url(../../fonts/CascadiaCode-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'CascadiaLight';
  src: url(../../fonts/CascadiaCode-ExtraLight.ttf) format('truetype');
}