@import '../globals';

.hp-container {
  // width: 1903px;
  // height: 947px;
  width: 100vw;
  height: 100vh;
  padding: 30px;
  display: grid;
  
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, 1fr);
  align-items: center;
  justify-items: center;
  font-family: 'AdineueProBold', adineue PRO;
  gap: 30px;
  
  @media (max-width: 1250px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    gap: 45px;
    height: 1700px;
  }

  @media (max-width: 750px) {
    height: 1900px;
  }
}