@import '../globals/index';

.am-container {
  // border: 1px solid red;
  font-family: 'CascadiaLight';
  width: 100%;
  color: white;
  padding-top: 80px;
  
  &__introduction {
    // border: 1px solid red;
    background-color: $soft-dark;
    padding: 120px clamp(16px, 24vw, 1196px);
    font-size: clamp(16px, 1.67vw, 32px);
    
    @media (max-width: 1250px) {
      padding: 120px clamp(16px, 20vw, 1000px);
    }

    @media (max-width: 750px) {
      padding: 90px clamp(16px, 16vw, 1000px);
    }

    &__title {
      // border: 1px solid red;
      font-family: 'AdineueProBold';
      font-size: clamp(40px, 5vw, 96px);
      
      & span {
        color: $orange;
      }
    }
    
    &__subtitle {
      // border: 1px solid red;
      font-family: 'CascadiaItalic';
      font-weight: 100;
      margin: 90px 0 70px;
      
      @media (max-width: 1250px) {
        margin: 80px 0 60px;
      }

      @media (max-width: 750px) {
        margin: 50px 0 30px;
      }
    }
    
    &__text {
      // border: 1px solid red;
      font-weight: 100;
      line-height: 1.3;

      @media (max-width: 750px) {
        // border: 1px solid red;
        line-height: 1.5;
      }
    }
  }

  &__about-my-skills-text {
    // border: 1px solid red;
    font-family: 'AdineueProBold';
    font-size: clamp(40px, 5vw, 96px);
    padding: 150px clamp(16px, 24vw, 1196px) 150px;
    
    @media (max-width: 1250px) {
      padding: 120px clamp(16px, 20vw, 1000px);
    }

    @media (max-width: 750px) {
      padding: 80px clamp(16px, 16vw, 1000px);
    }

    & span {
      color: $orange;
    }
  }
}